export const COLORS = {
    primary: '#5A7EFE',

    backgrounds: {
        fill: '#012D5A',
        gradient: 'linear-gradient(167deg, #002C59 0%, #0057B0 100%)',
        blue: 'rgba(225, 239, 255, 1)',
        light: 'rgba(249, 250, 255, 1)',
    },

    text: {
        white: 'rgba(233, 244, 255, 1)',
        dark: 'rgba(21, 23, 30, 1)',
        feature: 'rgba(4, 77, 159, 1)',
        green: 'rgba(20, 182, 65, 1)',
    },
}