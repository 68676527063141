import {FC, PropsWithChildren} from "react";
import {ContainerStyled} from "./styled";
import {SxProps, Theme} from "@mui/material";

interface TProps extends PropsWithChildren {
    flex?: boolean;
    column?: boolean;
    sx?: SxProps<Theme>;
    id?: string;
}
export const Container: FC<TProps> = ({
                                          children,
                                          column,
                                          flex = column,
                                          sx,
    id
                                      }) => {

    return (
        <ContainerStyled column={column} isFlex={flex} sx={sx} id={id}>
            {children}
        </ContainerStyled>
    )
}