import {Box, styled} from "@mui/material";

export const FrameworksStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    gap: '8rem',

    '& div.text': {
        display: 'flex',
        flexDirection: 'column',

        gap: '1.6rem',
        flexBasis: '60%',
        [theme.breakpoints.down('ipadPro_11')]: {flexBasis: '100%'},
    },

    '& div.image': {
        flexBasis: '40%',

        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },

        [theme.breakpoints.down('ipadPro_11')]: {flexBasis: '90%', maxWidth: '40rem'},
    },

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem', flexDirection: 'column'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))