import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Typo} from "../../../../../shared/components/typo";
import {ContactUsStyled} from "./styled";
import {ContactUsForm} from "./components/contactUsForm";
import {ANCHOR_CONTACT_US} from "../../../../barsEnvironment/constants";
import {Box} from "@mui/material";

// @ts-ignore
import Decor from "../../../../../shared/images/decor.svg";

export const ContactUs: FC = () => {
    // const theme = useTheme();
    // const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('contactUs');

    return (
        <Container id={ANCHOR_CONTACT_US}>
            <ContactUsStyled>
                <Box sx={{
                    backgroundImage: `url(${Decor})`,
                    backgroundRepeat: 'repeat-y',
                    backgroundSize: '110% 140%',
                    height: 'calc(100% + 20rem)',
                    width: '150%',
                    position: 'absolute',
                    zIndex: 2,
                    top: '-20rem',
                }}/>
                <div className={'text'}>
                    <Typo variant={"h1"} text={t('title')}/>
                    {/*<Typo variant={"body1"} text={t('description')}/>*/}
                </div>

                <ContactUsForm/>
            </ContactUsStyled>
        </Container>
    )
}