import {FallbackNs, useTranslation, UseTranslationResponse} from "react-i18next";


type TResponse<Ns = undefined, KPrefix = undefined> = Omit<UseTranslationResponse<FallbackNs<Ns>, KPrefix>, 'revDir'> & {
    revDir: boolean,
}

export const useMainTranslation = (key: string = ''): TResponse => {
    const {i18n, ...props} = useTranslation('', {keyPrefix: key});

    return {
        ...props,
        i18n,

        revDir: i18n.dir() === 'rtl',
    }
}