import {FC} from "react";
import {Typo} from "../../../../../../../shared/components/typo";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslation";
import {PricingGridItemStyled, PricingItemStyled} from "../../styled";
import {TPricingCard} from "../../constants";
import {COLORS} from "../../../../../../../shared/theme/colors";
import {Button, Divider} from "@mui/material";
import {Done} from "@mui/icons-material";
import {ANCHOR_CONTACT_US, PATH_REGISTRATION} from "../../../../../../barsEnvironment/constants";

type TProps = {
    card: TPricingCard,
}
export const PricingCard: FC<TProps> = ({card}) => {
    const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('pricing');

    return (
        <PricingGridItemStyled>
            <PricingItemStyled>
                <Typo variant={"subtitle1"} text={t(card.title)} color={COLORS.text.feature}/>

                <span className={'price'}>
                    {card.price > 0 ? '$' + card.price : tCommon('Contact us')}
                    {card.price > 0 && <span className={'perMonth'}>{t('/mo')}</span>}
                </span>

                <Divider flexItem sx={{marginBlock: '3.2rem', backgroundColor: '#D9D9D9'}}/>

                <div className={'description'}>
                    {card.description.map(d => (
                        <Typo key={d} variant={"body1"} text={t(d)} startIcon={<Done sx={{color: COLORS.text.green}}/>}/>
                    ))}
                </div>

                {!card.buttonContactUs ? (
                    <Button variant={"contained"} sx={{boxShadow: 'none'}} fullWidth href={PATH_REGISTRATION} target={'_blank'}>{tCommon('Get trial')}</Button>
                ) : (
                    <Button variant={"outlined"} sx={{boxShadow: 'none'}} fullWidth href={'#' + ANCHOR_CONTACT_US}>{tCommon('Contact us')}</Button>
                )}
            </PricingItemStyled>
        </PricingGridItemStyled>
    )
}