import {TTopBarNavigation} from "./types";

export const tobBarNavigation = [
    {
        title: 'Home',
        type: 'LINK',
        href: ''
    },
    {
        title: 'Features',
        type: 'LINK',
        href: 'features'
    },
    {
        title: 'Product',
        type: 'LINK',
        href: 'product'
    },
    {
        title: 'Pricing',
        type: 'LINK',
        href: 'pricing'
    },
    {
        title: 'Company',
        type: 'LINK',
        href: 'company'
    },
] satisfies TTopBarNavigation[];