import {Box, styled} from "@mui/material";
import {COLORS} from "../../../../../../shared/theme/colors";

export const ProductStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    position: 'relative',
    backgroundImage: COLORS.backgrounds.gradient,

    '& .container': {
        gap: '10rem',
        zIndex: 3,

        paddingBlock: '13rem',
        [theme.breakpoints.down('desktop1')]: { paddingBlock: '12rem' },
        [theme.breakpoints.down('ipadPro_11')]: { paddingBlock: '10rem' },
        [theme.breakpoints.down('iphone13Mini')]: { paddingBlock: '8rem' },

    },

    '& > .container > .text': {
        gap: '2rem',
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        color: COLORS.text.white,
        textAlign: 'center',
        maxWidth: 'min(80rem, 100%)'
    },

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))

export const ProductRowStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    '&:nth-of-type(odd)': { flexDirection: 'row-reverse' },

    gap: '8rem',

    '& > .text': {
        display: 'flex',
        flexDirection: 'column',

        color: COLORS.text.white,
        flexBasis: '45%',
        gap: '1.6rem',
    },

    '& > div.illustration': {
        flexBasis: '55%',
    },

    '& > div.illustration > img': {
        maxWidth: '100%',
        maxHeight: '100%',
    },

    [theme.breakpoints.down('ipadMini_8_3')]: {
        '&:nth-of-type(odd)': { flexDirection: 'column' },
        flexDirection: 'column',
        textAlign: 'start',
    },
}))