import {Box, styled} from "@mui/material";
import {COLORS} from "../../../../../../shared/theme/colors";

export const DashboardStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',

    position: 'relative',
    background: COLORS.backgrounds.gradient,

    '& .container': {
        gap: '3.2rem',
        zIndex: 3,
    },
    '& .text': {gap: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center', color: COLORS.text.white, textAlign: 'center', maxWidth: 'min(75rem, 100%)'},
    '& .actions': {gap: '3.2rem', display: 'flex', alignItems: 'center', justifyContent: 'center'},

    paddingBlock: '22rem 0',
    '& .illustration': {position: 'relative', maxHeight: '45rem', maxWidth: '50%', marginRight: '4rem', transform: 'scale(calc(7/4)) translateY(25%)'},
    marginBottom: 'min(calc(10vw * 3), 40rem)',


    [theme.breakpoints.down('w1920')]: {
        paddingBlock: '19.0rem 0',
    },
    [theme.breakpoints.down('desktop1')]: {
        paddingBlock: '17.0rem 0',
    },
    [theme.breakpoints.down('w1280')]: {
        paddingBlock: '18rem 0',
    },
    [theme.breakpoints.down('ipadPro_11')]: {
        paddingBlock: '18rem 0',
    },
    [theme.breakpoints.down('ipadMini_8_3')]: {
        paddingBlock: '16.6rem 0',
    },
}))