import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {FeatureStyled} from "./styled";
import {Grid} from "@mui/material";
import {featureCards} from "./constants";
import {FeatureCard} from "./components/featureCard";
import {ANCHOR_FEATURES} from "../../../../barsEnvironment/constants";

export const Feature: FC = () => {
    // const theme = useTheme();
    // const {t: tCommon} = useMainTranslation('common');
    // const {t} = useMainTranslation('trusted');

    return (
        <Container id={ANCHOR_FEATURES}>
            <FeatureStyled>
                <Grid columns={12} container spacing={4} justifyContent={"space-evenly"} alignItems={"stretch"}>
                    {featureCards.map(e => (
                        <FeatureCard key={e.title} card={e}/>
                    ))}
                </Grid>
            </FeatureStyled>
        </Container>
    )
}