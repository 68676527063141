import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

export const TermsAndConditions: FC = () => {

    // const theme = useTheme();
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',
            '& .MuiTypography-subtitle1': {
                marginBottom: '0',
            },
            '& .MuiTypography-subtitle2': {
                marginBottom: '0',
            },
            '& .MuiTypography-body1': {
                marginTop: '1rem',
                // fontSize: '1.6rem'
            },
            '& .MuiTypography-body2': {
                fontSize: '1.4rem'
            },
            '& ul': {
                listStyle: 'disc',
            },
        }}>
            <Typography variant={'h1'}>Terms and Conditions</Typography>
            <Typography variant={'body2'}>By using this website, you agree to the following terms and conditions.</Typography>
            <Typography variant={'body2'}>Date last modified: July 23, 2023</Typography>
            <Typography variant={'subtitle2'}>General</Typography>
            <Typography variant={'body1'}>By accessing or using this website (the “CompliCo Website”) of CompliCo WLL, with address at Office 2503, Floor 25, Al Moayyed Tower, Building 2504, Road 2832, Block 428, Al Seef District, Manama, Kingdom of Bahrain (“CompliCo”), you agree to be bound by these terms and conditions (these “Terms”), as may change from time to time, and the Privacy Policy, available at https://complico.me/privacy, as may change from time to time (these Terms together with the Privacy Policy, the “Terms & Conditions”), , If you do not agree with the Terms & Conditions, you must leave the CompliCo Website and not use it.</Typography>
            <Typography variant={'subtitle2'}>Materials</Typography>
            <Typography variant={'body1'}>All materials contained on the CompliCo Website are provided for informational purposes only and should not be construed as an advice, commercial offer, license, or professional relationship between you and CompliCo. No information provided on the CompliCo Website should be considered a substitute for your independent research.</Typography>
            <Typography variant={'subtitle2'}>Proprietary Rights</Typography>
            <Typography variant={'body1'}>All content, including but not limited to text, graphics, logos, images, and software, on the CompliCo Website is the property of CompliCo and is protected by copyright laws. You may not use, modify, reproduce or distribute any of the content without CompliCo’s prior written approval.</Typography>
            <Typography variant={'subtitle2'}>Lists of Customers, Partners or Investors</Typography>
            <Typography variant={'body1'}>CompliCo Website may include lists of customers, investors or partners who have invested in or partnered with CompliCo. These lists are for informational purposes only and do not constitute an endorsement of CompliCo or its products or services.</Typography>
            <Typography variant={'subtitle2'}>Contact</Typography>
            <Typography variant={'body1'}>CompliCo Website may provide a contact window or form such as ‘Contact Us’ or ‘Talk to an Expert’ for users to contact CompliCo (with clicking the ‘Get trial’ or ‘Contact Us’ button). Should you use it, you are solely responsible for the content of your messages and must not use the contact window or form to send spam or other unsolicited messages. CompliCo reserves the right to block users who abuse the contact window or form. Please note that if you do not fill in your correct and accurate contact details, CompliCo will not be able to contact you. For the avoidance of doubt, in any event, CompliCo has no obligation to contact you.</Typography>
            <Typography variant={'subtitle2'}>Links</Typography>
            <Typography variant={'body1'}>CompliCo Website may contain links to CompliCo’s services or products that may be subject to other terms of use or other provisions. You acknowledge that when you click on any such link you may be bound and subject to such terms of use or other provisions other than or further to the Terms and Conditions.</Typography>
            <Typography variant={'subtitle2'}>Disclaimer</Typography>
            <Typography variant={'body1'}>CompliCo Website may also contain links to third-party websites, services, or products that are not owned or controlled by CompliCo. CompliCo is not responsible for the content, privacy policies, or practices of any third-party websites and makes no guarantees or promises regarding such websites.</Typography>
            <Typography variant={'subtitle2'}>Opinions</Typography>
            <Typography variant={'body1'}>COMPLICO WEBSITE AND ITS CONTENT ARE PROVIDED "AS IS," WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. COMPLICO MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, OR SUITABLITY OF THE CONTENT.</Typography>
            <Typography variant={'subtitle2'}>Unlawful or Prohibited Use</Typography>
            <Typography variant={'body1'}>CompliCo Website may present opinions or testimonials from customers who have used CompliCo’s products or services. These opinions are the sole views of the customers and do not represent the views or opinions of CompliCo. CompliCo makes no guarantees or promises regarding the accuracy, reliability, or authenticity of these opinions.</Typography>
            <Typography variant={'subtitle2'}>Indemnification</Typography>
            <Typography variant={'body1'}>You may not reproduce, duplicate, copy or otherwise exploit any of the content on the CompliCo Website for a commercial or any unauthorized purpose without a prior written consent of CompliCo.</Typography>
            <Typography variant={'subtitle2'}>Data Protection and Privacy</Typography>
            <Typography variant={'body1'}>You hereby agree to defend, indemnify and hold CompliCo, their owners, employees, consultants and affiliates, harmless from and against all damages, claims, costs, charges and expenses (including attorneys’ fees) arising out of your use of the CompliCo Website.</Typography>
            <Typography variant={'subtitle2'}>Amendments</Typography>
            <Typography variant={'body1'}>In performing the Services, CompliCo will comply with CompliCo Privacy Policy, which is available at https://complico.me//privacy and incorporated herein by reference, as may change from time to time (the “Privacy Policy”). CompliCo highly regards your rights in your personal data and your privacy. The collection and use of your data is subject to the Privacy Policy.</Typography>
            <Typography variant={'subtitle2'}>Waiver</Typography>
            <Typography variant={'body1'}>CompliCo reserves the right, at its sole discretion, to modify or replace the Terms & Conditions at any time. You are advised to review the Terms & Conditions on a regular basis. By continuing to access or use the CompliCo Website, after any revisions become effective, you agree to be bound by the revised terms.</Typography>
            <Typography variant={'subtitle2'}>Severability</Typography>
            <Typography variant={'body1'}>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under the Terms & Conditions shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under the Terms & Conditions preclude further exercise of that or any other right granted herein.</Typography>
            <Typography variant={'subtitle2'}>Law and Jurisdiction</Typography>
            <Typography variant={'body1'}>If any provision of the Terms & Conditions is deemed to be void, invalid, unenforceable or illegal, CompliCo will replace such invalid provision while the other provisions shall continue in full force and effect.</Typography>
            <Typography variant={'subtitle2'}>No Employment or Agency Relationship</Typography>
            <Typography variant={'body1'}>The Terms & Conditions and your use of the Services are governed by the laws of the Kingdom of Bahrain The courts of competent jurisdiction located within The Kingdom of Bahrain, will have the exclusive jurisdiction over any and all disputes arising out of, relating to or concerning the Terms & Conditions or in which the Terms & Conditions are a material fact.</Typography>
            <Typography variant={'subtitle2'}>Entire Agreement</Typography>
            <Typography variant={'body1'}>No provision of the Terms & Conditions, or any part of the relationship between you and CompliCo, is intended to create nor shall they be deemed or construed to create any relationship between you and CompliCo other than as specifically referred to in the Terms & Conditions.</Typography>
            <Typography variant={'subtitle2'}>Contact Us</Typography>
            <Typography variant={'body1'}>The Terms & Conditions constitute the entire agreement between you and CompliCo with respect to the use of the CompliCo Website.</Typography>
        </Box>
    )
}