export const PATH_TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PATH_PRIVACY_POLICY = '/privacy';
export const PATH_EULA = '/eula';
export const PATH_TERMS_OF_SERVICE = '/tos';

export const ANCHOR_PRODUCT = 'product';
export const ANCHOR_FRAMEWORKS = 'frameworks';
export const ANCHOR_COMPANY = 'company';
export const ANCHOR_BROCHURE = 'brochure';
export const ANCHOR_FEATURES = 'features';
export const ANCHOR_PRICING = 'pricing';
export const ANCHOR_CONTACT_US = 'contact-us';

export const PATH_LOG_IN = 'https://app.complico.me';
export const PATH_REGISTRATION = 'https://app.complico.me/signup';
export const PATH_LINKEDIN = 'https://www.linkedin.com/company/complico/';
export const PATH_TWITTER = 'https://twitter.com/BeComplico';
export const PATH_FACEBOOK = 'https://www.facebook.com/complico';
