import {Box, styled} from "@mui/material";
import {COLORS} from "../../../../../../shared/theme/colors";

export const ContactUsStyled = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',

    background: COLORS.backgrounds.gradient,
    borderRadius: '1rem',

    '& div.text': {
        flexBasis: '45%',
        gap: '1.6rem',
        zIndex: 3,

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',

        '.MuiTypography-root': {
            color: COLORS.text.white,
            maxWidth: 'min(100%, 102.8rem)',
        },
    },

    gap: '10rem',
    padding: '6.4rem 10.4rem',
    [theme.breakpoints.down('desktop1')]: {padding: '6.4rem', gap: '5.4rem'},

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem', flexDirection: 'column', textAlign: 'center'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem'},
    [theme.breakpoints.down('ipadMini_8_3')]: {marginTop: '10rem', padding: '6.4rem 3.2rem'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))

export const ContactUsFormStyled = styled("form")(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    zIndex: 3,

    overflow: 'hidden',

    gap: '2rem',
    padding: '4rem 2.3rem',

    background: '#FFFFFF',
    boxShadow: '0rem 0.4rem 2rem rgba(0, 0, 0, 0.16)',
    borderRadius: '1rem',

    '& .MuiSvgIcon-root': {
        width: '2rem',
        height: '2rem',
    },

    '& > div.name': {
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',

        [theme.breakpoints.down('ipadMini_8_3')]: {
            flexDirection: 'column',
        },
    }
}))