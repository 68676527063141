import {FC, Fragment, ReactNode} from "react";
import {Box, Typography} from "@mui/material";
import {TypographyProps} from "@mui/material/Typography/Typography";

interface TProps extends TypographyProps {
    text: string;
    startIcon?: ReactNode;
}
export const Typo: FC<TProps> = ({text, startIcon, ...props}) => {

    const textContent = (
        <Typography {...props}>
            {text.split('\n').map((e) => (
                <Fragment key={e}>
                    {e}<br/>
                </Fragment>
            ))}
        </Typography>
    );

    return startIcon ? (
        <Box display={"flex"} alignItems={'center'} gap={'1.4rem'}>
            {startIcon}

            {textContent}
        </Box>
        ) : (
        <>
            {textContent}
        </>
    )
}