export type TPricingCard = {
    title: string,
    price: number,
    description: string[],
    buttonContactUs: boolean,
}

export const pricingCards = [
    {
        title: 'teams',
        price: -1,
        description: [
            'Unlimited Organization Size',
            'Unlimited Frameworks',
            'Unlimited Reports',
            'Manual Evidence Management',
            'Single Workspace & Editor',
        ],
        buttonContactUs: false,
    },
    {
        title: 'business',
        price: -1,
        description: [
            'Automatic Evidence Collection',
            'Unlimited Integrations',
            'Document Lifecycle Automation',
            'Audit log & Sandbox Workspace',
            'Up to 5 Editors',
        ],
        buttonContactUs: false,
    },
    {
        title: 'enterprise',
        price: -1,
        description: [
            'SAML',
            'Custom Frameworks',
            'Evidence Analysis',
            'Dedicated CSM',
            'Unlimited Editors',
        ],
        buttonContactUs: true,
    },
] satisfies TPricingCard[]