//Core
import React, {FC, useEffect} from 'react';
import {useLocation} from "react-router";

import {ShellStyled} from './styled'
import {ShellRoutes} from "./routes";

export const Shell: FC = () => {

    const location = useLocation();
    useEffect(() => {
        if (location.hash.length > 1 && location.hash.startsWith('#')) {
            setTimeout(() => {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: element.offsetTop - 130,
                    })
                }
            }, 100);

        } else {
            window.scrollTo(0, 0)
        }
    }, [location.pathname, location.hash]);
    
    return (
            <ShellStyled>
                <ShellRoutes/>
            </ShellStyled>
    )
}
