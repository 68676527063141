// Core
import {configureStore} from '@reduxjs/toolkit'

// Instruments
import {rootReducer} from './rootReducer';
import {middleware} from './middleware';

export const rootStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware)
    ,
    devTools:true
});


export type AppState = ReturnType<typeof rootStore.getState>
export type AppDispatch = typeof rootStore.dispatch



