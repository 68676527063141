import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Grid} from "@mui/material";
import {PricingStyled} from "./styled";
import {PricingCard} from "./components/pricingCard";
import {pricingCards} from "./constants";
import {Typo} from "../../../../../shared/components/typo";
import {ANCHOR_PRICING} from "../../../../barsEnvironment/constants";

export const Pricing: FC = () => {
    // const theme = useTheme();
    // const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('pricing');

    return (
        <Container id={ANCHOR_PRICING}>
            <PricingStyled>
                <Typo variant={"subtitle1"} text={t('pricing')}/>
                <Typo variant={"h1"} text={t('pricingTitle')}/>
                <Typo variant={"body1"} text={t('pricingDescription')}/>

                <Grid columns={12} container spacing={4} justifyContent={"space-evenly"} alignItems={"stretch"} sx={{mt: '3.2rem'}}>
                    {pricingCards.map(e => (
                        <PricingCard key={e.title} card={e}/>
                    ))}
                </Grid>
            </PricingStyled>
        </Container>
    )
}