import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Typo} from "../../../../../shared/components/typo";
import {FrameworksStyled} from "./styled";
import {ANCHOR_FRAMEWORKS} from "../../../../barsEnvironment/constants";
//@ts-ignore
import FrameworksImage from "../../../../../shared/images/frameworks/frameworks.png";

export const Frameworks: FC = () => {
    // const theme = useTheme();
    // const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('frameworks');

    return (
        <Container id={ANCHOR_FRAMEWORKS}>
            <FrameworksStyled>
                <div className="text">
                    <Typo variant={"h1"} text={t('frameworksTitle')}/>
                    <Typo variant={"body1"} text={t('frameworksDescription')}/>
                </div>
                <div className="image">
                    <img src={FrameworksImage} alt={'Frameworks illustration'}/>
                </div>
            </FrameworksStyled>
        </Container>
    )
}