import {Link, ListItem, styled, Typography} from "@mui/material";


export const UnderFooterText = styled(Typography, {
    shouldForwardProp: (prop) => !['isLink'].includes(prop as string)
})<{isLink?: boolean, component?: typeof Link, href?: string}>(({theme, isLink, ...props}) => ({
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: '#81939E',

    '&:hover': isLink ? {
        cursor: 'pointer',
        // color: '#4788C9',
    } : {}
}))

export const FooterListItem = styled(ListItem, {
    shouldForwardProp: (prop) => !['isTitle', 'isLink', 'isPath'].includes(prop as string)
})<{isTitle?: boolean, isLink?: boolean, isPath?: boolean, component?: typeof Link, href?: string}>(({theme, isTitle, isLink, isPath, ...props}) => ({
    ...(isTitle ? {
        fontWeight: 500,
        fontSize: '1.7rem',
        lineHeight: '2.55rem',
    } : {
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        color: '#616365',
    }),

    ...(isLink ? {color: '#4788C9'} : {}),

    '&:hover': isLink || isPath ? {
        color: '#072C51',
        cursor: 'pointer',
    } : {},
}))