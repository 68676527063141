import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {Box, Button} from "@mui/material";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {DashboardStyled} from "./styled";
import {Typo} from "../../../../../shared/components/typo";

// @ts-ignore
import DashboardSvg from "../../../../../shared/images/illustrations/dashboard.png";
// @ts-ignore
import Decor from "../../../../../shared/images/decor.svg";
import {ANCHOR_CONTACT_US, PATH_REGISTRATION} from "../../../../barsEnvironment/constants";

export const Dashboard: FC = () => {
    const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('dashboard');

    return (
        <DashboardStyled>
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'flex-end',
                top: 0,
                height: '100%',
                width: '100%',
                overflow: 'hidden',
            }}>
                <img src={Decor} style={{height: '150%', marginTop: '-15%'}} alt={'decor'}/>
            </Box>
            <Container column>
                <div className={'text'}>
                    <Typo variant={"title"} text={t('dashboardTitle')}/>
                    <Typo variant={"body1"} text={t('dashboardDescription')}/>
                </div>

                <div className={'actions'}>
                    <Button variant={"contained"} href={PATH_REGISTRATION} target={'_blank'}>
                        {tCommon('Get trial')}
                    </Button>
                    <Button variant={"outlined"} href={'#' + ANCHOR_CONTACT_US}>
                        {tCommon('Contact us')}
                    </Button>
                </div>

                <img src={DashboardSvg} alt={'DashboardSvg'} className={'illustration'}/>
            </Container>
        </DashboardStyled>
    )
}