import {ThemeOptions} from '@mui/material';
import {COLORS} from "./colors";

export const themeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            iphone13Mini: 375,
            ipadMini_8_3: 744,
            ipadPro_11: 834,
            w1080: 1080,
            w1280: 1280,
            desktop1: 1440,
            w1920: 1920,
        },
    },

    palette: {
        primary: {
            main: COLORS.primary,
            dark: '#1C42C9',
            light: '#62A2EB'
        },
        secondary: {
            main: COLORS.text.white,
        },

        text: {
            primary: COLORS.text.dark,
        },
    },

    typography: {
        fontFamily: `'Poppins', sans-serif`,
        fontSize: 14,
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightBold: 600,
    },

    components: {
        MuiButton: {
            variants: [
                {
                    props: {variant: 'text'}, style: ({theme}) => ({
                        textTransform: 'none',
                        fontSize: '1.6rem',
                        fontWeight: 600,
                        lineHeight: '3rem',
                    })
                },
                {
                    props: {variant: 'contained'}, style: ({theme}) => ({
                        padding: '1rem 2.2rem',
                        borderRadius: '3rem',
                        boxShadow: '0.2rem 0.2rem 2.2rem 0.0rem #197BE0',

                        textTransform: 'none',
                        fontSize: '1.6rem',
                        fontWeight: 600,
                        lineHeight: '3rem',

                        [theme.breakpoints.down('w1080')]: {
                            padding: '0.8rem 1.6rem',
                        },
                    })
                },
                {
                    props: {variant: 'outlined'}, style: ({theme}) => ({
                        padding: '1rem 2.2rem',
                        borderRadius: '3rem',
                        boxShadow: '0.2rem 0.2rem 2.2rem 0.0rem #197BE0',

                        textTransform: 'none',
                        fontSize: '1.6rem',
                        fontWeight: 600,
                        lineHeight: '3rem',

                        color: theme.palette.secondary.main,

                        [theme.breakpoints.down('w1080')]: {
                            padding: '0.8rem 1.6rem',
                        },
                    })
                },
            ],
        },

        MuiIconButton: {
            variants: [
                {
                    props: {color: "secondary"}, style: ({theme}) => ({
                        color: COLORS.text.white,
                        width: '4.6rem',
                        height: '4.6rem',
                        padding: '1.34rem',

                        '& svg': {
                            height: '2.5rem',
                            width: '2.5rem',
                        },
                    })
                },
            ],
        },

        MuiTypography: {
            variants: [
                {props: {variant: 'title'}, style: ({theme}) => ({
                        fontSize: '5.8rem',
                        fontWeight: 600,
                        lineHeight: '130%',

                        [theme.breakpoints.down('w1280')]: {
                            fontSize: '3.2rem',
                            lineHeight: '122%',
                        },
                        [theme.breakpoints.down('ipadMini_8_3')]: {
                            fontSize: '2.4rem',
                        },
                    })
                },
                {props: {variant: 'h1'}, style: ({theme}) => ({
                        fontSize: '4.8rem',
                        fontWeight: 600,
                        lineHeight: '122%',

                        [theme.breakpoints.down('w1280')]: {
                            fontSize: '3.2rem',
                            lineHeight: '122%',
                        },
                        [theme.breakpoints.down('ipadMini_8_3')]: {
                            fontSize: '2.4rem',
                        },
                    })
                },
                {props: {variant: 'h2'}, style: ({theme}) => ({
                        fontSize: '3.6rem',
                        fontWeight: 600,
                        lineHeight: '135%',

                        [theme.breakpoints.down('w1280')]: {
                            fontSize: '3.2rem',
                            lineHeight: '122%',
                        },
                        // [theme.breakpoints.down('ipadMini_8_3')]: {
                        //     fontSize: '2.4rem',
                        // },
                    })
                },
                {props: {variant: 'body1'}, style: ({theme}) => ({
                        fontSize: '1.6rem',
                        fontWeight: 400,
                        lineHeight: '135%',

                        [theme.breakpoints.down('w1280')]: {
                            fontSize: '1.4rem',
                            lineHeight: '128%',
                        },
                    })
                },
                {props: {variant: 'subtitle1'}, style: ({theme}) => ({
                        fontSize: '2.4rem',
                        fontWeight: 700,
                        lineHeight: '150%',
                        textTransform: 'uppercase',

                        color: theme.palette.primary.main,

                        [theme.breakpoints.down('w1280')]: {
                            fontSize: '2.0rem',
                        },
                    })
                },
                {props: {variant: 'subtitle2'}, style: ({theme}) => ({
                        fontSize: '1.8rem',
                        fontWeight: 600,
                        lineHeight: '2.8rem',

                        color: COLORS.text.feature,
                    })
                },
            ],
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: '1.6rem',
                },
            }
        },
    }
};

