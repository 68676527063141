import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

export const TermsOfService: FC = () => {

    // const theme = useTheme();
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',
            '& .MuiTypography-subtitle1': {
                marginBottom: '0',
            },
            '& .MuiTypography-subtitle2': {
                marginBottom: '0',
            },
            '& .MuiTypography-body1': {
                marginTop: '1rem',
                // fontSize: '1.6rem'
            },
            '& .MuiTypography-body2': {
                fontSize: '1.4rem'
            },
            '& ul': {
                listStyle: 'disc',
            },
        }}>
            <Typography variant={"h1"}>Terms of Service</Typography>
            <Typography variant={"body2"}>Date last modified: July 23, 2023</Typography>
            <Typography variant={"body1"}>BEFORE YOU SELECT THE "I ACCEPT" CHECKBOX AT THE BOTTOM OF THIS WINDOW, PLEASE READ CAREFULLY THE FOLLOWING TERMS OF SRVICE. BY CHOOSING THE "I ACCEPT" BUTTON YOU ARE (1) REPRESENTING THAT YOU ARE OVER THE AGE OF 18 AND HAVE THE CAPACITY AND AUTHORITY TO BIND YOURSELF AND YOUR EMPLOYER/E/ORGANIZATION TO THESE TERMS OF SERVICE; AND (2) CONSENTING ON BEHALF OF YOURSELF AND AS AN AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER/ORGANIZATION, TO BE BOUND BY THE FOLLOWING TERMS OF SERVICE. IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THE FOLLOWING TERMS OF SERVICE, OR DO NOT REPRESENT THE FOREGOING, DO NOT SELECT THE "I ACCEPT" CHECKBOX, AND IN WHICH CASE YOU MAY NOT PROCEED WITH SIGNING UP AND/OR IN AND/OR USING THE COMPLICO PLATFORM.</Typography>
            <Typography variant={"subtitle2"}>General</Typography>
            <Typography variant={"body1"}>These terms of service (these “Terms of Service”) in conjunction with the Privacy Policy, available at https://complico.me/privacy, as may change from time to time, the End User License Agreement available at https://complico.me/eula, as may change from time to time (the "EULA") , and any other agreement executed between you and CompliCo, constitute a legal and binding agreement, between you and CompliCo WLL, with address at Office 2503, Floor 25, Al Moayyed Tower, Building 2504, Road 2832, Block 428, Al Seef District, Manama, Kingdom of Bahrain (“CompliCo”), that governs your use of the CompliCo online platform at https://app.complico.me, as may change from time to time and any service therefrom (collectively, the “CompliCo Platform”). By accessing and using the CompliCo Platform, you accept these Terms of Service, without limitation or qualification. If you do not agree with these Terms of Service, you must immediately sign out and cease using CompliCo Platform.</Typography>
            <Typography variant={"subtitle2"}>Materials</Typography>
            <Typography variant={"body1"}>“you”, and “your” shall apply collectively to you as an individual and to the organization for which you are using the CompliCo Platform. You hereby acknowledge, warrant, and covenant that you have the authority to (i) use the CompliCo Platform; (ii) bind your organization to the terms of these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>User Accounts</Typography>
            <Typography variant={"body1"}>All materials contained on the CompliCo Platform are provided for informational purposes only and should not be construed as an advice, commercial offer, license, or professional relationship between you and CompliCo. No information provided on the CompliCo Platform should be considered a substitute for your independent research.</Typography>
            <Typography variant={"subtitle2"}>Proprietary Rights</Typography>
            <Typography variant={"body1"}>To use the CompliCo Platform , you must create an account. You agree to provide accurate, current and complete information about yourself during the registration process and to update such information to keep it accurate, current and complete. You are solely responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account. You may not share your password with anyone, or transfer or give access to the CompliCo Platform to anyone else.</Typography>
            <Typography variant={"subtitle2"}>License</Typography>
            <Typography variant={"body1"}>CompliCo Platform and any part thereof are protected by copyright, trademark, and other proprietary rights. You agree not to use, modify, reproduce, distribute, sell, license, reverse engineer or create derivative works based on CompliCo Platform or any part thereof, except as expressly permitted by these Terms of Service or as otherwise authorized by CompliCo in writing.</Typography>
            <Typography variant={"subtitle2"}>Use of CompliCo Platform</Typography>
            <Typography variant={"body1"}>CompliCo reserves the right to deactivate, cease, block or suspend, temporarily or permanently, your account and/or your ability to make use of the CompliCo Platform or any of the services and features thereof, for any reason, at its sole and absolute discretion.</Typography>
            <Typography variant={"subtitle2"}>Disclaimer</Typography>
            <Typography variant={"body1"}>Please note that CompliCo may, at its sole discretion, reject or deny your registration to the CompliCo Platform or any of the features or services thereof.</Typography>
            <Typography variant={"subtitle2"}>Unlawful or Prohibited Use</Typography>
            <Typography variant={"body1"}>The use of any of the CompliCo Platform, its features or services, is not and shall not be deemed as a sale, transfer or assignment of any rights, and is subject to the terms of the EULA.</Typography>
            <Typography variant={"subtitle2"}>Term and Termination</Typography>
            <Typography variant={"body1"}>Please note that the scope and nature of the CompliCo Platform may vary according to your specific workplace or specific binding agreement executed by your organization and CompliCo, as applicable and valid (the “Specific Agreement”), and you do not and shall not have any claim and/or demand on this matter.</Typography>
            <Typography variant={"subtitle2"}>Indemnification</Typography>
            <Typography variant={"body1"}>You agree to grant CompliCo the right to edit, supervise, and remove any content posted or shared by you in the CompliCo Platform</Typography>
            <Typography variant={"subtitle2"}>Limitation of Liability</Typography>
            <Typography variant={"body1"}>CompliCo at all times has the right, but not the obligation, to update, to upgrade and/or to adapt the CompliCo Platform or any part thereof and/or to change or remove data or information stored on its servers.</Typography>
            <Typography variant={"subtitle2"}>Data protection and Privacy</Typography>
            <Typography variant={"body1"}>Your use of the CompliCo Platform or any part thereof is at your own account and risk. You are responsible for any and all use by you, as well as for the integrity of the information you provide on the Services.</Typography>
            <Typography variant={"subtitle2"}>Amendments</Typography>
            <Typography variant={"body1"}>COMPLICO PLATFORM IS PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. BY USING ANY OF THEM, YOU HEREBY PRESENT AND WARRANT NOT TO USE FOR ANY ILLEGAL, IMMORAL OR UNAUTHORIZED PURPOSES, NOR TO KNOWINGLY OR PURPOSELY USE IN VIOLATION OF ANY RIGHT OF THIRD PARTY. YOUR USE DOES NOT AND SHALL NOT CREATE ANY PARTNERSHIP, JOINT VENTURE, AGENCY OR ANY OTHER RELATIONSHIP BETWEEN YOU AND COMPLICO. COMPLICO MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, OR AVAILABILITY OF COMPLICO PLATFORM, NOR DOES IT WARRANT ERROR OR DEFECTS FREE, ACCURACY, COMPLETENESS, MERCHANTABILITY, TITLE AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS OR THAT ANY ERRORS OR DEFECTS ON WILL BE CORRECTED.</Typography>
            <Typography variant={"subtitle2"}>Waiver</Typography>
            <Typography variant={"body1"}>You may not use CompliCo Platform for any purpose that is unlawful or prohibited by applicable law or by these Terms of Service, or interfere with any other user's use. You may not attempt to gain unauthorized access to CompliCo Platform.</Typography>
            <Typography variant={"subtitle2"}>Precedence</Typography>
            <Typography variant={"body1"}>You must notify CompliCo immediately in the event you become aware of any unauthorized access to or use of the CompliCo Platform by third parties under your username or password or any other possible breach in security.</Typography>
            <Typography variant={"subtitle2"}>Survival and Severability</Typography>
            <Typography variant={"body1"}>CompliCo is neither responsible nor liable for any loss or damage that may result from any unauthorized use.</Typography>
            <Typography variant={"subtitle2"}>Law and Jurisdiction</Typography>
            <Typography variant={"body1"}>You may not reproduce, duplicate, copy or otherwise exploit material on the CompliCo Platform for a commercial or any unauthorized purpose without a prior written consent from CompliCo. You further agree not to engage in any activity that interferes with or disrupts the CompliCo Platform or any part thereof. This includes, but is not limited to, transmitting any viruses, worms, defects, Trojan horses, malicious code, or any other similar software or programs that may damage the operation of the CompliCo Platform or any part thereof.</Typography>
            <Typography variant={"subtitle2"}>No Employment or Agency Relationship</Typography>
            <Typography variant={"body1"}>You agree and warrant that your use of the CompliCo Platform and any part thereof will not infringe upon any and all rights of third parties, and that such use will always be in conformity with all applicable laws and regulations.</Typography>
            <Typography variant={"subtitle2"}>Equitable Relief</Typography>
            <Typography variant={"body1"}>You may use the CompliCo Platform from the when you are granted access by CompliCo and until the earlier of: (i) the date on which the term set by the Specific Agreement, if applicable; (ii) the date in which you terminate according to a prior written notice by you to CompliCo when you , or (iv) on the date CompliCo notifies you in writing (the “CompliCo Termination”). CompliCo reserves the right, in its sole discretion, to terminate, suspend, remove, block, deactivate, and/or disable your access and use to all or part of the CompliCo Platform, with or without notice and with or without cause, other than provided otherwise in the Specific Agreement, if applicable.</Typography>
            <Typography variant={"subtitle2"}>Headings</Typography>
            <Typography variant={"body1"}>In the event you notify CompliCo in writing within 14 of the days, of any of your Users commencement of using the CompliCo Platform, of you terminating within such 14 days, then any fees paid to CompliCo for the use of or subscription to the CompliCo Platform for the use of the CompliCo Platform for more than such 14 days, shall be refunded to you or your organization, as applicable. Other than such termination within 14 days, all payments and fees by you, shall be non-refundable.</Typography>
            <Typography variant={"subtitle2"}>Limitation of Time to File Claims</Typography>
            <Typography variant={"body1"}>in the event that the CompliCo Termination occurred other than due to an act or omission or any breach of by you, prior to the end of a paid subscription by you to CompliCo, for the use of the CompliCo Platform, then CompliCo shall refund the relative portion of the already paid fees for the number of months remaining until the end of such subscription.</Typography>
            <Typography variant={"subtitle2"}>Entire Agreement</Typography>
            <Typography variant={"body1"}>You hereby agree to defend, indemnify and hold CompliCo, their owners, employees, consultants and affiliates, harmless from and against all damages, claims, costs, charges and expenses (including attorneys’ fees) arising out of your use of the CompliCo Platform.</Typography>
        </Box>
    )
}