import {cloneElement, ReactElement} from "react";
import {Box, styled, useScrollTrigger} from "@mui/material";
import {CSSProperties} from "@mui/styled-engine";
import {COLORS} from "../../../../shared/theme/colors";

const ElevationScrollCss: CSSProperties = {
    padding: '1.5rem 0rem',
    background: COLORS.backgrounds.fill,
}

export function ElevationScroll(props: { window?: () => Window; children: ReactElement }) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return cloneElement(children, {
        elevation: 0,
        sx: trigger
            ? {
                ...ElevationScrollCss,
                boxShadow: '0.0rem 0.4rem 1.2rem 0.0rem #07529E',
            }
            : {
                ...ElevationScrollCss
            }
    });
}

export const HeaderStyled = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .logo': {
        cursor: 'pointer',
        height: '3vw',
        maxHeight: '3.7rem',
        minHeight: '2.14rem',
        width: 'auto',

        [theme.breakpoints.down('w1080')]: {
            height: '4.5vw',
            maxHeight: '3.0rem',
        },
    }
}))

export const HeaderTextButtons = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '40%',

    '& a': {
        textDecoration: 'none',

        color: COLORS.text.white,
        fontSize: '1.8rem',
        fontWeight: 500,
        lineHeight: '3rem',

        '&:hover': {
            color: COLORS.backgrounds.light,
        },
    },
}))

export const HeaderActionButtons = styled(Box)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    gap: '3.2rem',
    [theme.breakpoints.down('w1280')]: {
        gap: '2.0rem',
    },
}))