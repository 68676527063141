import React, {FC, Fragment, PropsWithChildren, useEffect} from "react";

import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {ElevationScroll, HeaderActionButtons, HeaderStyled, HeaderTextButtons} from "./styled";
import {Container} from "../../../shared/components/container";

//@ts-ignore
import {ReactComponent as CompliCoIcon} from '../../../shared/images/CompliCo.svg'
import {tobBarNavigation} from "./constants";
import {useMainTranslation} from "../../../shared/hooks/useMainTranslation";
import {getHref} from "./helpers";
import {useOpen} from "../../../shared/hooks/useOpen";
import {Close, Menu} from "@mui/icons-material";
import {PATH_LOG_IN, PATH_REGISTRATION} from "../constants";
import {COLORS} from "../../../shared/theme/colors";
import {Typo} from "../../../shared/components/typo";
import {useLocation} from "react-router";

export const Header: FC<PropsWithChildren> = (props) => {
    const location = useLocation();
    const theme = useTheme();
    const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('header');

    const needToHide = useMediaQuery(theme.breakpoints.down('w1080'));
    const down440 = useMediaQuery(theme.breakpoints.down('iphone13Mini'));

    const {isOpened, handleClose, handleOpenReverse} = useOpen();
    useEffect(() => {
        handleClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needToHide, location.pathname, location.hash])

    return (
        <>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar color={"transparent"} >
                    <Container>
                        <HeaderStyled>
                            <Link href={'/'}>
                                <CompliCoIcon className={'logo'}/>
                            </Link>

                            {!needToHide && (
                                <HeaderTextButtons>
                                    {tobBarNavigation.map(el => (
                                        <Fragment key={el.title}>

                                            {el.type === 'LINK' && (
                                                <Link href={getHref(el.href)}>
                                                    {t(el.title)}
                                                </Link>
                                            )}

                                        </Fragment>
                                    ))}
                                </HeaderTextButtons>
                            )}

                            <HeaderActionButtons>
                                {!needToHide && (
                                    <Button variant={"text"} color={"secondary"} component={Link} href={PATH_LOG_IN} target={'_blank'}>
                                        {tCommon('Log in')}
                                    </Button>
                                )}

                                <Button variant={"contained"} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                                    {tCommon('Get trial')}
                                </Button>

                                {needToHide && (
                                    <IconButton color={"secondary"} onClick={handleOpenReverse}>
                                        <Menu/>
                                    </IconButton>
                                )}
                            </HeaderActionButtons>
                        </HeaderStyled>
                    </Container>
                </AppBar>
            </ElevationScroll>

            <Drawer
                anchor={"right"}
                open={isOpened}
                onClose={handleClose}
            >
                <Box p={'1rem 2rem'} width={!down440 ? '400px' : '100vw'} display={"flex"} flexDirection={'column'} alignItems={'flex-end'}>
                    <Box display={"flex"} alignItems={"center"} gap={'0.9rem'}>
                        {/*<Button variant={"contained"} size={"medium"} component={Link} href={PATH_REGISTRATION} target={'_blank'} sx={{boxShadow: 'none'}}>*/}
                        {/*    {t('Get trial')}*/}
                        {/*</Button>*/}

                        <IconButton size={"large"} sx={{'& svg': {width: '2.8rem', height: '2.8rem'}}}
                                    onClick={handleOpenReverse}>
                            <Close/>
                        </IconButton>
                    </Box>

                    <Box width={'100%'} mt={'1.6rem'}>
                        <List sx={{width: '100%'}}>
                            {tobBarNavigation.map((element, index, array) => (
                                <Fragment key={JSON.stringify(element)}>
                                    <ListItem sx={{
                                        width: '100%',
                                        '&:hover': {
                                            backgroundColor: COLORS.backgrounds.light,
                                        },
                                    }}  component={Link} href={'#' + element.href}>
                                        <ListItemText sx={({breakpoints}) => ({
                                            cursor: 'pointer',
                                            textAlign: 'end',

                                            [breakpoints.down('iphone13Mini')]: {
                                                textAlign: 'center',
                                            },
                                        })}>
                                            <Typo variant={'subtitle2'} text={t(element.title)} color={COLORS.text.dark}/>
                                        </ListItemText>
                                    </ListItem>

                                    {/*<Divider/>*/}
                                </Fragment>
                            ))}

                            <ListItem sx={{width: '100%', '&:hover': {backgroundColor: COLORS.backgrounds.light}}} component={Link} href={PATH_LOG_IN} target={'_blank'}>
                                <ListItemText sx={({breakpoints}) => ({
                                    cursor: 'pointer',
                                    textAlign: 'end',

                                    [breakpoints.down('iphone13Mini')]: {
                                        textAlign: 'center',
                                    },
                                })}>
                                    <Typo variant={'subtitle2'} text={t('Log in')}/>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}
