// @ts-ignore
import Kyc from "../../../../../shared/images/product/kyc.png"
// @ts-ignore
import Vica from "../../../../../shared/images/product/vica.png"
// @ts-ignore
import Risk from "../../../../../shared/images/product/risk.png"
// @ts-ignore
import Editor from "../../../../../shared/images/product/editor.png"

export type TProduct = {
    title: string;
    description: string;
    image: string;
};

export const products = [
    {
        title: 'KycTitle',
        description: "KycDescription",
        image: Kyc,
    },
    {
        title: 'DocumentsTitle',
        description: "DocumentsDescription",
        image: Editor,
    },
    {
        title: 'VicaTitle',
        description: "VicaDescription",
        image: Vica,
    },
    {
        title: 'RiskTitle',
        description: "RiskDescription",
        image: Risk,
    },
] satisfies TProduct[];