// Core
import {combineReducers} from "@reduxjs/toolkit";

// Reducers
import {snackReducer} from "../../domain/barsEnvironment/snack/store/slice";


export const rootReducer = combineReducers({
    snack: snackReducer,
});

