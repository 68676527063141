import React, {FC, PropsWithChildren} from "react";
import {Box, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLocation} from "react-router";
import {Header} from "../../barsEnvironment/header";
import {Footer} from "../longPage/components/10-footer";
import {Container} from "../../../shared/components/container";
import {
    PATH_EULA,
    PATH_PRIVACY_POLICY,
    PATH_TERMS_AND_CONDITIONS,
    PATH_TERMS_OF_SERVICE
} from "../../barsEnvironment/constants";
import {COLORS} from "../../../shared/theme/colors";

export const TermsPage: FC<PropsWithChildren> = ({children}) => {

    const {pathname} = useLocation();

    const theme = useTheme();
    const isDown800 = useMediaQuery(theme.breakpoints.down('ipadMini_8_3'));
    return (
        <>
            <Header/>

            <Container sx={{
                mt: '10rem',
                gap: '4rem',
                display: 'flex',
                flexDirection: !isDown800 ? 'row' : 'column',
                pb: '1rem',
                color: 'rgba(0, 0, 0, 0.6)',
                fontSize: '1.6rem',

                '& .MuiTypography-root': {
                    mt: '2rem',
                    mb: '1.2rem'
                },
                '& .MuiTypography-root.MuiTypography-body2': {
                    mt: '0',
                    mb: '0'
                },

                '& ul': {
                    // listStyle: 'inside',
                    ml: '2rem',
                    mt: '1.2rem',
                },
                '& li': {
                    listStyleType: 'disc',
                    mb: '0.4rem'
                },
            }}
            >
                <Box display={"flex"} flexDirection={"column"} mb={'auto'} sx={{
                    background: '#FFFFFF',

                    boxShadow: '0.4rem 0.4rem 0.8rem #D4E1FA',
                    backdropFilter: 'blur(2.25rem)',
                    borderRadius: '1rem',
                    p: '3.2rem',
                }}>
                    <Typography variant={'h3'} href={PATH_TERMS_AND_CONDITIONS} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_TERMS_AND_CONDITIONS ? COLORS.primary : COLORS.text.dark, textDecoration: 'none'}}>
                        Terms and Conditions
                    </Typography>
                    <Typography variant={'h3'} href={PATH_PRIVACY_POLICY} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_PRIVACY_POLICY ? COLORS.primary : COLORS.text.dark, textDecoration: 'none'}}>
                        Privacy Policy
                    </Typography>
                    <Typography variant={'h3'} href={PATH_TERMS_OF_SERVICE} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_TERMS_OF_SERVICE ? COLORS.primary : COLORS.text.dark, textDecoration: 'none'}}>
                        Terms of Service
                    </Typography>
                    <Typography variant={'h3'} href={PATH_EULA} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_EULA ? COLORS.primary : COLORS.text.dark, textDecoration: 'none'}}>
                        EULA
                    </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    {children}
                </Box>
            </Container>

            <Footer/>
        </>
    )
}