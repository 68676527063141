// @ts-ignore
import Frameworks from "../../../../../shared/images/features/frameworks.svg";
// @ts-ignore
import Analysis from "../../../../../shared/images/features/analisys.svg";
// @ts-ignore
import Audit from "../../../../../shared/images/features/audit.svg";
// @ts-ignore
import Collectors from "../../../../../shared/images/features/collectors.svg";
// @ts-ignore
import Vika from "../../../../../shared/images/features/vika.svg";


export type TFeatureCard = {
    icon: string,
    title: string,
    description: string,

    isMain?: boolean,
}

export const featureCards = [
    {
        icon: '',
        title: 'featureTitle',
        description: 'featureDescription',

        isMain: true,
    },
    {
        icon: Frameworks,
        title: 'Unlimited frameworks',
        description: 'Unlimited frameworks description',
    },
    {
        icon: Vika,
        title: 'VICA',
        description: 'VicaDescription',
    },
    {
        icon: Collectors,
        title: 'Collectors',
        description: 'CollectorsDescription',
    },
    {
        icon: Analysis,
        title: 'Analisys',
        description: 'Analisys description',
    },
    {
        icon: Audit,
        title: 'Audit space',
        description: 'Audit space description',
    },
] satisfies TFeatureCard[]