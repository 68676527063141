import {FC} from "react";
import {FeatureIconStyled} from "../../styled";

type TProps = {
    icon: string, title: string,
}
export const FeatureIcon: FC<TProps> = ({icon, title}) => {

    return (
        <FeatureIconStyled>
            <img src={icon} alt={title + ' icon'}/>
        </FeatureIconStyled>
    )
}