import {FC} from "react";
import {ProductRowStyled} from "../../styled";
import {TProduct} from "../../constants";
import {Typo} from "../../../../../../../shared/components/typo";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslation";

interface IProps {
    product: TProduct
}
export const ProductRow: FC<IProps> = ({product: {title, image, description}}) => {
    const {t} = useMainTranslation('product');

    return (
        <ProductRowStyled>
            <div className={'text'}>
                <Typo text={t(title)} variant={'h1'}/>
                <Typo text={t(description)} variant={'body1'}/>
            </div>

            <div className={'illustration'}>
                <img src={image} alt={title + ' illustration'}/>
            </div>
        </ProductRowStyled>
    )
};