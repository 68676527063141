import React, {ChangeEvent, FC, useState} from "react";
import {ContactUsFormStyled} from "../../styled";
import {Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslation";
import {Trans} from "react-i18next";
import {PATH_PRIVACY_POLICY, PATH_TERMS_AND_CONDITIONS} from "../../../../../../barsEnvironment/constants";
import {LoadingButton} from "@mui/lab";
import {useSnack} from "../../../../../../barsEnvironment/snack/hooks/useSnack";
import {sendContactUsEmail} from "../../../../../../../shared/api";
import {TContactForm} from "../../../../../../../shared/types";
import {emailRegex} from "../../../../../../../shared/helpers";

export const ContactUsForm: FC = () => {
    const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('contactUs');

    const snack = useSnack();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [form, setForm] = useState<TContactForm>({
        firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''
    })
    const handleChange = (field: keyof TContactForm) => (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm(prev => ({...prev, [field]: e.target.value}))
    }

    const handleSubmit = () => {
        if (form.firstName.trim() === '') {
            snack('First name should not be empty', "error");
            return;
        }
        if (form.lastName.trim() === '') {
            snack('Last name should not be empty', "error");
            return;
        }

        if (!emailRegex.test(form.email)) {
            snack(`${form.email} doesn't looks like 'email'`, "error");
            return;
        }

        if (form.message.trim() === '') {
            snack('Message should not be empty', "error");
            return;
        }

        if (!checked) {
            snack('You need agree to the Terms', "error");
            return;
        }

        try {
            sendContactUsEmail({
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                companyName: form.companyName,
                contactNumber: form.contactNumber,
                message: form.message,
            }, (message) => snack(message, "error"));
            snack('Message sent', 'success');
            setForm({firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''})
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ContactUsFormStyled>
            <div className={'name'}>
                <TextField variant={"outlined"} label={t('First Name')} className={'small'} fullWidth disabled={isLoading}
                           onChange={handleChange('firstName')} value={form.firstName}/>
                <TextField variant={"outlined"} label={t('Last name')} className={'small'} fullWidth disabled={isLoading}
                           onChange={handleChange('lastName')} value={form.lastName}/>
            </div>
            <TextField variant={"outlined"} label={t('E-mail')} fullWidth disabled={isLoading}
                       onChange={handleChange('email')} value={form.email}/>
            <TextField variant={"outlined"} label={t('Company name')} fullWidth disabled={isLoading}
                       onChange={handleChange('companyName')} value={form.companyName}/>
            <TextField variant={"outlined"} label={t('Your Message')} fullWidth disabled={isLoading} multiline minRows={3}
                       onChange={handleChange('message')} value={form.message}/>

            <FormControlLabel
                disabled={isLoading} checked={checked}
                control={<Checkbox onChange={e => setChecked(e.target.checked)}/>}
                label={(
                    <Typography variant={"body1"}>
                        <Trans i18nKey={"contactUs.iAgreeWith"}>
                            I agree to the <a href={PATH_TERMS_AND_CONDITIONS}>Terms and Conditions</a> and <a href={PATH_PRIVACY_POLICY}>Privacy Policy</a>.
                        </Trans>
                    </Typography>
                )}
            />

            <LoadingButton variant={"contained"} sx={{boxShadow: 'none'}} fullWidth onClick={handleSubmit} loading={isLoading}>{tCommon('Submit')}</LoadingButton>
        </ContactUsFormStyled>
    )
}