import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Typo} from "../../../../../shared/components/typo";
import {ANCHOR_BROCHURE} from "../../../../barsEnvironment/constants";
//@ts-ignore
import BrochureImage from "../../../../../shared/images/brochure/brochure.png";
//@ts-ignore
import BrochureFile from "./CompliCo_Solution_Brief.pdf";
import {Button} from "@mui/material";
import {BrochureStyled} from "./styled";

export const Brochure: FC = () => {
    // const theme = useTheme();
    const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('brochure');

    // const download = () => {
    //     const a = document.createElement('a');
    //     a.href = BrochureFile;
    //     a.download = 'CompliCo Solution Brief.pdf';
    //     a.click();
    // };

    return (
        <Container id={ANCHOR_BROCHURE}>
            <BrochureStyled>
                <div className="text">
                    <Typo variant={"h1"} text={t('brochureTitle')}/>
                    <Typo variant={"body1"} text={t('brochureDescription')}/>
                    <Button variant={"contained"} sx={{width: 'fit-content', boxShadow: 'none'}}
                            href={BrochureFile} download={'CompliCo Solution Brief.pdf'}
                    >
                        {tCommon('Download')}
                    </Button>
                </div>
                <div className="image">
                    <img src={BrochureImage} alt={'Brochure illustration'}/>
                </div>
            </BrochureStyled>
        </Container>
    )
}