import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {I18nextProvider} from "react-i18next";

import {Theme, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {useMainTranslation} from "../hooks/useMainTranslation";
import {getTheme} from "./theme";
import rtlPlugin from 'stylis-plugin-rtl';
import {CacheProvider} from '@emotion/react';
import createCache from '@emotion/cache';
import {prefixer} from 'stylis';

import moment from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import 'moment/locale/ar';
import 'moment/locale/he';
import {StyleSheetManager} from "styled-components";

const cacheRtl = createCache({
    key: 'muirtl',
    //@ts-ignore
    stylisPlugins: [prefixer, rtlPlugin],
});
const cacheLtr = createCache({
    key: "muiltr",
    prepend: true,
    stylisPlugins: []
});

export const ModificationThemeProvider: FC<PropsWithChildren> = ({children}) => {
    const { i18n, revDir } = useMainTranslation();
    const [theme, setTheme] = useState<Theme>(getTheme(revDir));

    useEffect(() => {
        moment.locale(i18n.language);
        setTheme(getTheme(revDir));
        document.dir = revDir ? 'rtl' : 'ltr';

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language, revDir])

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <CacheProvider value={revDir ? cacheRtl : cacheLtr}>
                    {/*//@ts-ignore*/}
                    <StyleSheetManager stylisPlugins={[rtlPlugin]}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            {children}
                        </LocalizationProvider>
                    </StyleSheetManager>
                </CacheProvider>
            </ThemeProvider>
        </I18nextProvider>
    )
}