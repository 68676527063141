import React, {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {LongPage} from "../../pages/longPage";
import {TermsPage} from "../../pages/terms";
import {PATH_EULA, PATH_PRIVACY_POLICY, PATH_TERMS_AND_CONDITIONS, PATH_TERMS_OF_SERVICE} from "../constants";
import {TermsAndConditions} from "../../pages/terms/components/termsAndConditions";
import {PrivacyPolicy} from "../../pages/terms/components/privacyPolicy";
import {TermsOfService} from "../../pages/terms/components/termsOfService";
import {Eula} from "../../pages/terms/components/eula";

export const ShellRoutes: FC = () => {

    return (
        <Routes>
            <Route path={''} element={<LongPage/>}/>

            <Route path={PATH_TERMS_AND_CONDITIONS} element={<TermsPage><TermsAndConditions/></TermsPage>}/>
            <Route path={PATH_PRIVACY_POLICY} element={<TermsPage><PrivacyPolicy/></TermsPage>}/>
            <Route path={PATH_TERMS_OF_SERVICE} element={<TermsPage><TermsOfService/></TermsPage>}/>
            <Route path={PATH_EULA} element={<TermsPage><Eula/></TermsPage>}/>

            <Route path="*" element={<Navigate to={''} />} />
        </Routes>
    )
}