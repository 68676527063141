import {Box, styled} from "@mui/material";

type TContainerStyledProps = {
    isFlex?: boolean;
    column?: boolean;
}
export const ContainerStyled = styled(Box, {shouldForwardProp: (prop) => (
        !['isFlex', 'column'].includes(prop.toString())
    )})<TContainerStyledProps>(({theme, column, isFlex,}) => ({
    width: '100%',
    maxWidth: '144.0rem',

    margin: '0 auto',
    paddingInline: '2rem',

    display: isFlex ? 'flex' : undefined,
    flexDirection: column ? 'column' : 'row',
    alignItems: column ? 'center' : undefined,
}))
ContainerStyled.defaultProps = {
    className: 'container'
}