import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {ProductStyled} from "./styled";
import {Typo} from "../../../../../shared/components/typo";
import {ANCHOR_PRODUCT} from "../../../../barsEnvironment/constants";
import {products} from "./constants";
import {ProductRow} from "./components/productRow";
import {Box} from "@mui/material";
// @ts-ignore
import Decor from "../../../../../shared/images/decor.svg";

export const Product: FC = () => {
    // const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('product');

    return (
        <ProductStyled id={ANCHOR_PRODUCT}>
            <Box sx={{
                backgroundImage: `url(${Decor})`,
                backgroundRepeat: 'repeat-y',
                backgroundSize: '140% 45%',
                height: 'calc(100% + 10rem)',
                width: '150%',
                position: 'absolute',
                zIndex: 2,
                top: '-10rem',
            }}/>
            <Container column>
                <div className={'text'}>
                    <Typo variant={"subtitle1"} text={t('product')}/>
                    <Typo variant={"h1"} text={t('productTitle')}/>
                    <Typo variant={"body1"} text={t('productDescription')}/>
                </div>

                {products.map((e, index) => (
                    <ProductRow key={e.title} product={e}/>
                ))}
            </Container>
        </ProductStyled>
    )
}