import {Box, Grid, styled} from "@mui/material";
import {COLORS} from "../../../../../../shared/theme/colors";

export const FeatureStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))

export const FeatureGridItemStyled = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
        maxWidth: 'min(100%, 20rem)',
        maxHeight: 'min(100%, 7rem)',
        // maxHeight: '7rem',
    },
}))
FeatureGridItemStyled.defaultProps = {
    item: true,
    w1280: 4,
    ipadMini_8_3: 6,
    iphone13Mini: 12,
}

interface IFeatureItemStyledProps {
    isMain?: boolean
}
export const FeatureItemStyled = styled(Box, {shouldForwardProp: (prop) => (
        !['isMain'].includes(prop.toString())
    )})<IFeatureItemStyledProps>(({ theme , isMain}) => ({
    display: 'flex',
    flexDirection: 'column',

    width: '100%',
    height: '100%',
    gap: '1rem',

    backgroundColor: !isMain ? COLORS.backgrounds.light : undefined,
    border: !isMain ? '1px solid #D8EAFC' : undefined,
    padding: !isMain ? '3.2rem 2rem' : '0',
    borderRadius: '1rem',
}))

export const FeatureIconStyled = styled(Box)(({ theme }) => ({
    width: '6.4rem',
    height: '6.4rem',
    borderRadius: '50%',
    padding: '1.5rem',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: COLORS.backgrounds.blue,
    marginBottom: '2.4rem',

    '& img': {
        maxWidth: '100%',
        maxHeight: '100%',
    }
}))