import {useState} from "react";

export const useOpen = () => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const handleOpen = () => setIsOpened(true);
    const handleClose = () => setIsOpened(false);
    const handleOpenReverse = () => setIsOpened(prev => !prev);

    return {
        isOpened,
        handleOpen,
        handleClose,
        handleOpenReverse
    }
}