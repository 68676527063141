import {Box, styled} from "@mui/material";

export const CompanyStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    gap: '1.6rem',

    '& .MuiTypography-root': {
        maxWidth: 'min(100%, 102.8rem)',
    },

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))