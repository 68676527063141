import styled, { keyframes } from "styled-components";
import {FC} from "react";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 60px;
  height: 60px;
  border-radius: 50%;  
`;

const Wrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background-color:rgba(255,255,255,0.7);
    z-index:1000;
`;

export const Spinner:FC = () => {
    return <Wrapper><Loader/></Wrapper>
}
