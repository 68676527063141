import {FC} from "react";
import {LongPageStyled} from "./styled";
import {Dashboard} from "./components/1-dashboard";
import {Feature} from "./components/3-feature";
import {Product} from "./components/4-product";
import {Frameworks} from "./components/5-frameworks";
import {Pricing} from "./components/6-pricing";
import {Company} from "./components/7-company";
import {Brochure} from "./components/8-brochure";
import {ContactUs} from "./components/9-contactUs";
import {Footer} from "./components/10-footer";

export const LongPage: FC = () => {

    return (
        <LongPageStyled>
            <Dashboard/>
            {/*<Trusted/>*/}
            <Feature/>
            <Product/>
            <Frameworks/>
            <Pricing/>
            <Company/>
            <Brochure/>
            <ContactUs/>
            <Footer/>
        </LongPageStyled>
    )
}