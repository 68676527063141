//Core
import React, {Suspense} from 'react';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";


import {Helmet} from "react-helmet";

//I18NEXT
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {i18nextPlugin} from 'translation-check';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


//Common components
import {Spinner} from "../shared/components/spinner";

//Pages
import {Shell} from "../domain/barsEnvironment/shell";
import {devEnv} from "../shared/constants";
import {rootStore} from "../shared/redux";
import {ModificationThemeProvider} from "../shared/theme";
import {Header} from "../domain/barsEnvironment/header";
import {SnackbarProvider} from "notistack";
import {Snack} from "../domain/barsEnvironment/snack";


//I18
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(i18nextPlugin)
    .use(HttpApi)
    .init({
        debug: devEnv,
        // supportedLngs: ['en', 'ar'],
        supportedLngs: ['en'],
        fallbackLng: 'en',
        backend: {
            loadPath: '/assets/locales/{{lng}}.json?' + new Date().getTime(), // Add timestamp to avoid caching
        },

        missingKeyNoValueFallbackToKey: true,
        interpolation: {
            escapeValue: false,
        },

        // detection: {},
    })


export default function App() {

    return (
        <Suspense fallback={ <Spinner/> }>
            <Provider store={rootStore}>
                <ModificationThemeProvider>
                    <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} preventDuplicate style={{fontSize: '16px'}}>
                        {devEnv ? (
                            <Helmet>
                                <meta name="robots" content="noindex"></meta>
                            </Helmet>
                        ) : (
                            <>
                            </>
                        )}

                        <Snack />
                        <BrowserRouter>
                            <Header/>
                            <Shell/>
                        </BrowserRouter>
                    </SnackbarProvider>
                </ModificationThemeProvider>
            </Provider>
        </Suspense>
    );
}
