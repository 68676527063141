import {Box, styled} from "@mui/material";

export const ShellStyled = styled(Box)(({theme}) => ({
    width: '100%',
    height: '100%',

    padding: '0',
    margin: '0',

    overflow: 'hidden',
    position: 'relative',
}))