import {FC} from "react";
import {FeatureGridItemStyled, FeatureItemStyled} from "../../styled";
import {TFeatureCard} from "../../constants";
import {Typo} from "../../../../../../../shared/components/typo";
import {useMainTranslation} from "../../../../../../../shared/hooks/useMainTranslation";
import {FeatureIcon} from "../featureIcon";

type TProps = {
    card: TFeatureCard,
}
export const FeatureCard: FC<TProps> = ({card}) => {
    const {t} = useMainTranslation('feature');

    return (
        <FeatureGridItemStyled>
            <FeatureItemStyled isMain={card.isMain}>
                {!card.isMain ? (
                    <>
                        <FeatureIcon icon={card.icon} title={t(card.title)}/>
                        <Typo variant={"subtitle2"} text={t(card.title)}/>
                        <Typo variant={"body1"} text={t(card.description)}/>
                    </>
                ) : (
                    <>
                        <Typo variant={"subtitle1"} text={t('Feature suite')} sx={{marginBottom: '3.2rem'}}/>
                        <Typo variant={"h2"} text={t(card.title)}/>
                        <Typo variant={"body1"} text={t(card.description)}/>
                    </>
                )}

            </FeatureItemStyled>
        </FeatureGridItemStyled>
    )
}