import React, {FC} from "react";
import {Box, Grid, Link, List, useMediaQuery, useTheme} from "@mui/material";
import {useLocation} from "react-router";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Container} from "../../../../../shared/components/container";
import {FooterListItem, UnderFooterText} from "../../../../barsEnvironment/styled";
import {
    ANCHOR_COMPANY,
    ANCHOR_FEATURES,
    ANCHOR_PRICING,
    ANCHOR_PRODUCT,
    PATH_PRIVACY_POLICY,
    PATH_TERMS_AND_CONDITIONS
} from "../../../../barsEnvironment/constants";

export const Footer: FC = () => {
    const {t} = useMainTranslation('common');

    const {pathname} = useLocation();
    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('desktop1'));
    const down800 = useMediaQuery(theme.breakpoints.down('ipadMini_8_3'));

    const AboutUs = () => (
        <List>
            <FooterListItem isTitle>{t('About us')}</FooterListItem>
            <FooterListItem sx={{textAlign: 'start'}}>{t("About us description")}</FooterListItem>
        </List>
    )

    const Home = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={''}>{t('Home')}</FooterListItem>
        </List>
    )

    const Feature = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_FEATURES}>{t('Features')}</FooterListItem>
        </List>
    )

    const Product = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_PRODUCT}>{t('Product')}</FooterListItem>
        </List>
    )

    const Pricing = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_PRICING}>{t('Pricing')}</FooterListItem>
        </List>
    )

    const Company = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_COMPANY}>{t('Company')}</FooterListItem>
        </List>
    )

    const Contacts = () => (
        <>
            <List>
                <FooterListItem isTitle>{t('Contacts')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: go@complico.me"}>{'go@complico.me'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{t('Legal Inquiries')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: legal@complico.me"}>{'legal@complico.me'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{t('Investor Relations')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: investors@complico.me"}>{'investors@complico.me'}</a></FooterListItem>
            </List>
        </>
    )

    const UnderFooter = () => (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={'3.2rem'}>
                <UnderFooterText isLink component={Link} href={PATH_TERMS_AND_CONDITIONS}>{t('Terms and conditions')}</UnderFooterText>
                <UnderFooterText isLink component={Link} href={PATH_PRIVACY_POLICY}>{t('Privacy policy')}</UnderFooterText>
            </Box>
            <UnderFooterText textAlign={'center'} mt={'0.8rem'}>{t('All rights')}</UnderFooterText>
        </>
    )

    if (down800) return (
        <Container sx={{mt: '15rem', flexDirection: 'column', paddingBottom: '3.2rem'}}>
            <Grid container columns={12}>
                <Grid item iphone13Mini={6}>
                    <AboutUs/>
                    {/*<SocialButtons/>*/}
                    <Contacts/>
                </Grid>

                <Grid item iphone13Mini={6}>
                    <Home/>
                    <Feature/>
                    <Product/>
                    <Pricing/>
                    <Company/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    if (down1366) return (
        <Container sx={{mt: '12rem', flexDirection: 'column', paddingBottom: '3.2rem'}}>
            <Grid container columns={12}>
                <Grid item iphone13Mini={4}>
                    <AboutUs/>
                    {/*<SocialButtons/>*/}
                </Grid>

                <Grid item iphone13Mini={4}>
                    <Home/>
                    <Feature/>
                    <Product/>
                    <Pricing/>
                    <Company/>
                </Grid>

                <Grid item iphone13Mini={4}>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    return (
        <Container sx={{mt: '17rem', flexDirection: 'column', paddingBottom: '3.2rem'}}>
            <Grid container columns={12}>
                <Grid item iphone13Mini={4}>
                    <AboutUs/>
                    {/*<SocialButtons/>*/}
                </Grid>

                <Grid item iphone13Mini={2}/>

                <Grid item iphone13Mini={2}>
                    <Home/>
                    <Feature/>
                    <Product/>
                </Grid>

                <Grid item iphone13Mini={2}>
                    <Pricing/>
                    <Company/>
                </Grid>

                <Grid item iphone13Mini={2}>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    )
}
