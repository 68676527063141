import {Box, Grid, styled} from "@mui/material";
import {COLORS} from "../../../../../../shared/theme/colors";

export const PricingStyled = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',

    gap: '1.6rem',

    '& .MuiTypography-root': {
        maxWidth: 'min(100%, 93.5rem)',
    },

    marginTop: '16rem',
    [theme.breakpoints.down('w1920')]: {marginTop: '14rem'},
    [theme.breakpoints.down('w1280')]: {marginTop: '11rem'},
    [theme.breakpoints.down('ipadPro_11')]: {marginTop: '10rem'},
    [theme.breakpoints.down('iphone13Mini')]: {marginTop: '9rem'},
}))

export const PricingGridItemStyled = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& img': {
        maxWidth: 'min(100%, 20rem)',
        maxHeight: 'min(100%, 7rem)',
        // maxHeight: '7rem',
    },
}))
PricingGridItemStyled.defaultProps = {
    item: true,
    w1280: 4,
    ipadMini_8_3: 6,
    iphone13Mini: 12,
}

interface IPricingItemStyledProps {

}
export const PricingItemStyled = styled(Box, {shouldForwardProp: (prop) => (
        ![''].includes(prop.toString())
    )})<IPricingItemStyledProps>(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'start',

    width: '100%',
    height: '100%',
    gap: '1rem',

    border: `1px solid ${COLORS.text.white}`,
    padding: '4.4rem 6.4rem',
    borderRadius: '1rem',

    '& span.price': {
        color: COLORS.primary,
        fontSize: '4rem',
        fontWeight: 600,
        lineHeight: '170%',
    },
    '& span.perMonth': {
        fontSize: '2rem',
        fontWeight: 500,
        lineHeight: '170%',
    },

    '& div.description': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',

        fontSize: '1.6rem !important',
        gap: '1.6rem',

        marginBottom: '3.2rem',

        'svg': {
            width: '2.4rem',
            height: '2.4rem',
        },
    },

    '& .MuiButton-outlined': {
        color: COLORS.text.dark,
    },

    '&:hover': {
        boxShadow: '0.27rem 0.27rem 1.02rem 0rem #B7C1D6',
    }
}))