import React, {FC} from "react";
import {Container} from "../../../../../shared/components/container";
import {useMainTranslation} from "../../../../../shared/hooks/useMainTranslation";
import {Typo} from "../../../../../shared/components/typo";
import {CompanyStyled} from "./styled";
import {ANCHOR_COMPANY} from "../../../../barsEnvironment/constants";

export const Company: FC = () => {
    // const theme = useTheme();
    // const {t: tCommon} = useMainTranslation('common');
    const {t} = useMainTranslation('company');

    return (
        <Container id={ANCHOR_COMPANY}>
            <CompanyStyled>
                <Typo variant={"subtitle1"} text={t('company')}/>
                <Typo variant={"h1"} text={t('companyTitle')}/>
                <Typo variant={"body1"} text={t('companyDescription')}/>
            </CompanyStyled>
        </Container>
    )
}