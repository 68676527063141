import {createSelector, createSlice} from "@reduxjs/toolkit";
import {v4 as uuidv4} from "uuid";

import {AppState} from "../../../../shared/redux";
import {TSnackSlice} from "../types";

const initialState: TSnackSlice = {
  notifications: [],
}

const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    addErrorSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "error"}]
    },
    addInfoSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "info"}]
    },
    addSuccessfulSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "success"}]
    },
    removeSnack: (slice, { payload: id }) => {
      slice.notifications = slice.notifications.filter( note => note.key !== id)
    }
  }
})

export const snackReducer = snackSlice.reducer;
export const {
  addErrorSnack,
  removeSnack,
  addInfoSnack,
  addSuccessfulSnack
} = snackSlice.actions;

const selectSelf = (state: AppState) => state.snack;
export const snackSelector = createSelector(selectSelf, state => state);
export const snackNotificationsSelector = createSelector(selectSelf, state => state.notifications);