import axios from "axios";
import {PATH_SERVER} from "../constants";

export type EmailContactUs = {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    companyName: string;
    contactNumber: string;
};

export const sendContactUsEmail = async (data: EmailContactUs, errorSnack: (message: string) => void) => (
    axios
        .post(`${PATH_SERVER}/hooks/contactus`, data)
        .catch((e: any) => {
            errorSnack('Error sending email');
            throw e;
        })
)